<template>
  <div class='dashboard-main'>
    <h1 class='page__title'>Dashboard</h1>
    <div class='dashboard-main__container'>

    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';


export default defineComponent({
    setup() {
    },
    data() {
      return {
        loading: false,

      };
    },
    async created() {

    },
  });
</script>
<style scoped lang='scss'>
  .dashboard-main {
    &__container {
      @apply flex flex-wrap gap-6 mt-5;
    }

    &__column {
      flex: 1 1 20rem;
    }

    &__column-title {
      @apply flex items-center font-medium text-base px-5 py-3 text-gray-600 overflow-y-auto;
      max-height: 5rem;
      height: 100%;
    }

    &__empty-message {
      @apply px-5 py-3 bg-white rounded-lg drop-shadow-sm font-bold;
      text-align: center;
    }
  }
</style>
